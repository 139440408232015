/*  */
.search {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  /* width: 100%;
  height: 100%; */
  background-color: #fff;
  outline: none;
  border-radius: 1rem;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
  padding: 1.25rem;
  border: 0;
  font-size: 1rem;
  color: #8f9396;
  /* font-weight: 600; */

  /* width: 53.5rem; */
  /* width: 34.5rem; */
  /* width: 15.5rem; */
}
/* .search :nth-child(1) {
  flex-grow: 2;
} */

.reset {
  position: absolute;
  /* left: 50%; */
  right: 1rem;
  background: #ff5350;
  box-shadow: 0 0 20px #ff5350;
  /* height: 60%;
  width: 60%;
  margin: 20%; */
  border-radius: 25%;
  width: 1.5rem;
  padding: 0.5rem;
  /* height: 100px; */
  /* width: 100px; */
  /* margin: auto; */
  transition: 200ms all;
}

.reset:hover {
  filter: saturate(200%);
  transform: scale(1.1);
  cursor: pointer;
}

.PokeSearchBar {
  gap: 2rem;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  /* display: flex;
  align-items: center;
  justify-content: center; */
  /* width: 90%; */
  /* display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center; */
  /* flex-wrap: wrap;
  gap: 2rem;
  margin: 1rem 0; */
}

@media screen and (max-width: 589px) {
  .search {
    width: 14.5rem;
  }
}

@media screen and (min-width: 589px) {
  .search {
    width: 33.5rem;
  }
}

@media screen and (min-width: 893px) {
  .search {
    width: 52.5rem;
  }
}

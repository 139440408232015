.PokeDetailsPage {
  width: 100%;
  height: 100%;
  display: flex;

  align-items: center;
  justify-content: center;
}

.PokeDetails {
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr;
  /* flex-direction: row; */
  /* align-items: center; */
  /* justify-content: space-evenly; */
  /* width: 100%;
  height: 100%; */
  /* background-color: #f5f5f5;
  border-radius: 10px;*/
  border-radius: 2rem;
  /* box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75); */
  border-radius: 1.5rem;
  box-shadow: 0 5px 10px rgba(154, 160, 185, 0.05),
    0 15px 40px rgba(166, 173, 201, 0.2);
  text-align: center;
  background-color: white;
  /* width: 75%; */
  padding: 0.75rem;
  margin: 0 auto;
  animation: fadeIn 0.5s ease-in-out;
  /* margin-top: 2rem; */
}

.PokeDetails-header {
  padding: 1rem;
}

.PokeDetails-body {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.PokeDetails-image {
  width: 20rem;
  /* width: 25%; */
  /* -moz-transform: scale(0.5);
  -webkit-transform: scale(0.5);
  transform: scale(0.5); */
}

.PokeDetails-number {
  font-size: 1.25rem;
  font-weight: 700;
  color: gray;
}

.PokeDetails-name {
  margin-top: 0.5rem;
  font-size: 2rem;
  font-weight: 700;
  color: #000;
  /* margin: 0.75rem 0 0.75rem 0; */
  text-transform: capitalize;
}

.PokeDetails-genus {
  font-size: 1rem;
  font-weight: 500;
  color: gray;
  margin: 0.5rem 0;
}

.PokeDetails-types {
  /* margin-top: 0.5rem; */
  gap: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  /* justify-content: space-between; */
  align-items: center;
}

.PokeDetails-type:hover {
  filter: saturate(200%);
  transform: scale(1.1);
}

.PokeDetails-type {
  /* font-size: 0.9rem; */
  font-weight: 700;
  color: #fff;
  text-transform: uppercase;
  border-radius: 0.5rem;
  padding: 0.5rem;
}

.PokeDetails-title {
  /* font-style: oblique; */
  /* text-decoration: underline !important; */
  margin-top: 1rem;
  margin-bottom: 0.5rem;
  font-size: 1.1rem;
  font-weight: 700;
  color: #000;
  /* margin: 0.75rem 0 0.75rem 0; */
  text-transform: uppercase;
  letter-spacing: 0.1rem;
}

.PokeDetails-section {
  /* margin: 1rem 0; */
}

.PokeDetails-description {
  /* font-size: 1rem; */
  /* align-self: center; */
  margin: 0 auto;
  /* text-align: center; */
  width: 20rem;
}

.PokeDetails-abilities {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}

.PokeDetails-ability {
  font-weight: 600;
  /* border: 1px solid black; */
  background-color: #f6f8fc;
  padding: 0.5rem 1.5rem;
  border-radius: 1rem;
  text-transform: capitalize;
}

.PokeDetails-info {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}

.PokeDetails-box {
  font-weight: 600;
  /* border: 1px solid black; */
  background-color: #f6f8fc;
  padding: 0.5rem 1.5rem;
  border-radius: 1rem;
  text-transform: capitalize;
}

.icon {
  border-radius: 100%;
  width: 2rem;
  padding: 0.5rem;
  /* margin: auto; */
  transition: 200ms all;
}

.icon:hover {
  filter: saturate(200%);
  transform: scale(1.1);
  cursor: pointer;
}

.PokeDetails-weaknesses {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}

.PokeDetails-weaknes {
  /* width: 3rem; */
}

.PokeDetails-stats {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0.75rem;
}

.PokeDetails-stat {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: 600;
  /* border: 1px solid black; */
  background-color: #f6f8fc;
  padding: 0.5rem 0.25rem;
  border-radius: 2rem;

  text-transform: capitalize;
  font-size: 0.9rem;
}

.PokeDetails-stat p {
  margin: 0.25rem 0;
}

.PokeDetails-stat-type {
  padding: 0.3rem;
  border-radius: 2rem;
  /* margin-bottom: 0.5rem; */
  color: white;
}

.PokeDetails-evolutions {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}

.PokeDetails-evolution {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  /* border: 1px solid black; */
  /* background-color: #f6f8fc; */
  /* padding: 0.5rem;
  border-radius: 2rem; */

  text-transform: capitalize;
  font-size: 0.9rem;
}

.PokeDetails-evolution img:hover {
  filter: saturate(200%);
  scale: 1.1;
  /* border-radius: 25%; */
  /* background-color: #f6f8fc; */
}

.PokeDetails-evolution-trigger {
  /* padding: 0.25rem;
  border-radius: 2rem;
  margin-bottom: 0.5rem; */
  width: 7rem;
  color: #a9adae;
  background-color: #f6f8fc;
  padding: 0.5rem 1.25rem;
  border-radius: 2rem;
  margin: 0 0.5rem;
}

.PokeDetails-evolution-image {
  width: 7rem;
}

.PokeDetails-footer {
  /* margin-top: 2rem; */
  grid-column: 1 / -1;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
}

.PokeDetails-navigation {
  background-color: #f6f8fc;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  /* gap: 75%; */
  /* gap: 1rem; */
  margin-top: 2rem;
  border-radius: 1.5rem;
  padding: 1rem;
  text-transform: capitalize;
  /* gap: 0.5rem; */
}

.PokeDetails-navigation img {
  width: 3rem;
}

.PokeDetails-navigation-prev {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
}

.PokeDetails-navigation-next {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
}

.PokeDetails-navigation-arrow {
  width: 1rem;
  height: 1rem;
}

.right-arrow {
  /* margin-left: 1rem; */
  /* margin-right: 0.5rem; */
  transform: rotate(180deg);
}

.left-arrow {
  /* margin-right: 1rem; */
  /* margin-left: 0.5rem; */
}

.vertical-line {
  /* Draw a vertical line */
  border-left: 2px solid #000;
  height: 100%;
  width: 0;
  /* margin: 0 0.5rem; */
}

.PokeDetails-navigation-pokemon-name {
  font-weight: 600;
  font-size: 1rem;

  text-transform: capitalize;
}

.PokeDetails-evolution-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}

.close {
  /* display: flex; */
  position: absolute;
  /* left: 50%; */
  top: 1.25rem;
  right: 1.25rem;
  background: #ff5350;
  box-shadow: 0 0 20px #ff5350;
  /* height: 60%;
  width: 60%;
  margin: 20%; */
  border-radius: 25%;
  width: 1.5rem;
  padding: 0.5rem;
  /* height: 100px; */
  /* width: 100px; */
  /* margin: auto; */
  transition: 200ms all;
}

.close:hover {
  filter: saturate(200%);
  transform: scale(1.1);
  cursor: pointer;
}

@media (max-width: 819px) {
  .PokeDetails {
    display: flex;
    border-radius: 0;
    width: 100%;
    height: 100%;
    flex-direction: column;
    /* flex-direction: row; */
    align-items: center;
    justify-content: space-evenly;
  }

  .PokeDetails-navigation {
    width: 90vw;
  }

  .PokeDetails-evolutions {
    flex-direction: column;
  }

  .PokeDetails-evolution {
    flex-direction: column;
  }

  .PokeDetails-navigation-prev p,
  .PokeDetails-navigation-next p {
    display: none;
  }

  .PokeDetails-stats {
    gap: 0.5rem;
  }
}

.PokeDex {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 2rem;
  margin: 1rem 0;
}

.Home {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  gap: 1rem;
  /* gap: 2rem; */
  margin: 1rem 0;
}

.close-icon {
  border-radius: 25%;
  width: 1rem;
  padding: 0.5rem;
  transition: 200ms all;
  cursor: pointer;
}

.Home-type {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 0.9rem;
  font-weight: 700;
  color: #fff;
  text-transform: uppercase;
  border-radius: 0.5rem;
  padding: 0.5rem;
}

.Pokedex-Logo:hover {
  /* filter: saturate(200%); */
  transform: scale(1.01);
  cursor: pointer;
}

@media screen and (max-width: 589px) {
  .Pokedex-Logo {
    width: 20rem;
  }
}
